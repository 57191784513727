


 

 .message_container { 

    background: url('../img/contacto_izei.jpg') center center/cover no-repeat;
    background-position: center!important;
    width: 100%;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    height: 100%!important;

   

 }

 .message_brackets {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: no-repeat center/70% url(../img/brackets.png);
    height: 550px;
 }

 br {
    display:none!important;
 }

 .text_h1{

    color:#fff;font-size:100px;letter-spacing:normal;line-height:90px; margin:0 auto;
    margin-top: 0px; font-family: 'Fjalla One'; margin-bottom: 20px; max-width: 1000px
 }

 .text_h2 {

    color:#fff; font-size: 16px; font-family: 'Roboto'; line-height: 19px; font-weight:500; 
 }

 .text_p {
    color: #fff; font-size: 18px;  font-family: 'Roboto'; line-height: 23px;font-weight: 100; max-width: 750px; margin:0 auto; margin-bottom: 20px;
 }



 @media screen and (max-width:1100px){
    .text_h1{font-size:78px; line-height: 77px; max-width: 800px}
}
@media screen and (max-width:992px){
    .text_h1{font-size:40px; line-height: 50px; max-width: 600px}
    .message_brackets {
        background: no-repeat center/55% url(../img/brackets_responsive.png);
     }
     br {
        display:initial!important;
    }
    
    
}


@media screen and (max-width:690px){
    .text_h1{font-size:45px;line-height:48px}
    .message_brackets {
        background: no-repeat center/85% url(../img/brackets_responsive.png);
     }
     br {
        display:initial!important;
    }
    
    
}
@media screen and (max-width:623px){
    .text_h1{font-size:40px;line-height:43px}
    .message_brackets {
        background: no-repeat center/85% url(../img/brackets_responsive.png);
     }
     br {
        display:initial!important;
    }
    
}
@media screen and (max-width:475px){
    .text_h1{font-size:25px;line-height:33px}
    .text_h2{font-size:15px;}
    .text_p{font: normal normal normal 0.8em/1.4em Roboto!important;
        text-align: center;}
    .message_brackets {
        background: no-repeat center/85% url(../img/brackets_responsive.png);
     }

     br {
         display:initial!important;
     }
     
    .message_container {
      background-size: cover;
      background-position: center;
     
    }
  
   
}
