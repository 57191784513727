
div.why_body{
  background-color: #F3F3F3!important;
  padding-top: 100px;
  padding-bottom: 100px;

}


asana-card-body{
  background-color: #F3F3F3;
}

h1.title-h1-asana{

  text-align: center;
  font-family: 'Fjalla One';
  font-weight: bold;
}

div.card__container_asana{

  display: flex;
  flex-flow: column;
  align-items: center;
 
  margin-right: 0px!important;
  margin-left: 0px!important;
  padding-left: 60px!important;
  padding-right: 60px!important;
  margin-top:10px!important;

}

div.card__wrapper_asana {
    position: relative;
    margin: 20px 0 20px;
    width: 85%;
}

.img-card-asana{
  margin-left: 50px!important;
}

p.card-text-asana{

  text-align: left;
  font: normal normal normal 16px/25px Roboto;
  letter-spacing: 0px;
  color: #848AA2;
  opacity: 1;
  padding-left: 25px;
  padding-right: 30px!important;
  margin-top: 0px!important;
  padding-top: 20px!important;
}



@media only screen and (min-width: 320px) and (max-width: 480px) {


  #card-why.card__container_asana{
    display: flex;
    flex-flow: column;
    align-items: center!important;
    justify-content: center!important;
    margin-right: 0px!important;
    margin-left: 0px!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
    margin-top: 10px!important;
  }

  img.img-card-asana{
    margin-left: 0px!important;
  }


  p.card-text-asana{

    text-align: center!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
   
  }
  
  #cardTextPqAsana{
    padding-left: 0px!important;
    margin-right: 0px!important;
    margin-left: 0px!important;
    padding-right: 0px!important;
  }


  #pCardTextPqAsana{
    padding-left: 0px!important;
    padding-right: 0px!important;
    margin-right: 20px!important;
    margin-left: 20px!important;
  }

  #cardBodyId{
    text-align: center!important;
  }
 
  h1.title-h1-asana{

    font: normal normal 600 22px/50px 'Fjalla One'!important;
    color: #343434!important;
  }
  

}



@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {


  #card-why.card__container_asana{
    display: flex;
    flex-flow: column;
    align-items: center!important;
    justify-content: center!important;
    margin-right: 0px!important;
    margin-left: 0px!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
    margin-top: 10px!important;
  }

  img.img-card-asana{
    margin-left: 0px!important;
  }


  p.card-text-asana{

    text-align: center!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
   
  }
  
  #cardTextPqAsana{
    padding-left: 0px!important;
    margin-right: 0px!important;
    margin-left: 0px!important;
    padding-right: 0px!important;
  }


  #pCardTextPqAsana{
    padding-left: 0px!important;
    padding-right: 0px!important;
    margin-right: 0px!important;
    margin-left: 0px!important;
  }

  #cardBodyId{
    text-align: center!important;
  }
 
  h1.title-h1-asana{

    font: normal normal 600 22px/50px 'Roboto'!important;
    color: #343434!important;
  }
  

 

}