
  .title {

    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-70)/var(--unnamed-line-spacing-84) var(--unnamed-font-family-marr-sans-cond);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    
    font: normal normal 600 42px/52px 'Roboto';
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    width: 680px;
    height: 155px;
    margin-left: 110px;
    padding-top: 300px;
    
    
    /*font-family: 'Fjalla One', sans-serif;
    font: normal normal normal 20px/28px 'Roboto';
    */
  }


  .hero-container {
    background: url('../img/asanaIntro.png') no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    
   

    
  }

  .bck-from {
    background-color: rgb(231, 231, 231);
    border-radius: 24px;
    margin-top: 133px;
    width: 410px!important;
    
    
  }

  .control-from {

    border-radius: 14px!important;
    padding-left: 18px!important;
  }

  .col-section-style {

    padding-top:0px;
    padding-bottom: 0px!important;
    margin-bottom: 0px!important;

  }
  

  .padding-from {

    padding-left: 30px;
    padding-right: 30px;
    
  }

  .padding-from-btn {

    padding-left: 45px;

  }

  .btn-style-asana {

      
    background: #523BE4 0% 0% no-repeat padding-box!important;
    border-radius: 5px!important;
    opacity: 1!important;
    border-color: #523BE4!important;
    margin-top: 20px;
    margin-left: 30px;
    margin-bottom: 60px;
    width: 150px;
    height: 45px;
    font-family: 'Roboto';
    font-size: 14px!important;  
    

  }
  .sendEmail-h1 {
          
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 30px/var(--unnamed-line-spacing-42) var(--unnamed-font-family-marr-sans-cond);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-565656);
    text-align: left;
    font: normal normal 600 20px/42px Fjalla One;
    letter-spacing: 0px;
    color: #565656;
    opacity: 1;
    padding-left: 43px;
    padding-top: 45px;
    padding-right: 30px;
  }

  .sendEmail-p {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 17px/25px var(--unnamed-font-family-roboto);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-707070);
    text-align: left;
    font: normal normal normal 17px/25px Roboto;
    letter-spacing: 0px;
    color: #848AA2;
    opacity: 1;
    padding-left: 45px;
  }

  .sendEmail-p-unidad{

    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 17px/25px var(--unnamed-font-family-roboto);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-707070);
    text-align: left;
    font: normal normal normal 17px/25px Roboto;
    letter-spacing: 0px;
    color: #848AA2;
    opacity: 1;
    padding-left: 45px;
    padding-left: 5%!important;
  }


  .grid {

    display: grid;
    grid-template-columns: 60% auto;
  }




  .form {
    padding-bottom: 110px;
    
    padding-left:95px;
  }

  
  .logo-style-hero {
    
    
    margin-top: 120px;
    margin-left: 110px;
    
   
  }

  
 
  .style-lada{

    margin-bottom: 0px!important;
    padding-bottom: 0px!important;
  }

  .body-s {

    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 28px/33.6px var(--unnamed-font-family-roboto);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal normal 20px/28px 'Roboto';
    letter-spacing: 0px;
    color: #F8F8F8;
    opacity: 1;
   
    padding-top: 140px;
    margin-left: 110px;

    top: 665px;
    left: 140px;
    width: 636px;
    height: 68px;
   
  }

  .logo-con {
    padding-top: 150px;
    margin-left: 110px;
   
    
  }


  @media screen and (max-width: 960px) {
    .grid {
      grid-template-columns: 1fr;
    }

    .title {
      font-size: 17px!important;
      margin: 99px!important;
    }

   
 
    
  }

  

  @media (min-device-height: 667px) 
  and (min-device-width: 375px) 
  and (-webkit-min-device-pixel-ratio: 3){
    

    .title {
     

      font: normal normal 600 24px 'Fjalla One'!important;
      margin: 0px!important;
      width:70%;
      height:auto;
      padding-top: 15%;
      padding-bottom: 0%;
      text-align: center;
      margin-left: 15%!important;

    }

    .body-s {
     

      padding-top: 0%;
      font: normal normal 600 18px 'Fjalla One'!important;
      margin: 30px!important;
      width:90%;
      height: 100%;
      text-align: center;
      margin-left: 5%!important;
    }

    .logo-style-hero {

      display:none;
    }

    .body {
      width:100%!important;
    }

    .grid {
      display:grid;
      grid-template-columns:1fr;
    }

    .form {
      
      padding-left:0px!important;
      margin-left: 0px!important;
      margin-top: 0%!important;
      padding-top: 0%!important;
      display:grid;
      width:auto;
      justify-content: center!important;
      
    
      
    }

  
    .bck-from {
      margin-top: 5%!important;
      width: 314px!important;
      height: auto;
    }


    .sendEmail-h1 {

      font: normal normal 600 15px 'Fjalla One';
    }

    .sendEmail-p {

      font: normal normal 600 14px 'Roboto';
    }

    
    
  } 



  @media only screen and (max-width: 640px) {

   
    .logo-bottom {
      background: url('../img/asanapartners.png') center no-repeat;
      width: 160px!important;
      height:71px!important;
      margin-left: 29%!important;
      background-size: 160px 71px;
      margin-bottom: 100px!important;
      margin-top: 0px!important;

    }
  
    .col-contact {
      padding-bottom: 1em!important;
    }

   

    
    .title {
     

      font: normal normal 600 40px/55px 'Fjalla One'!important;
      margin: 0px!important;
      width:70%;
      height:auto;
      padding-top: 40px;
      padding-bottom: 0%;
      text-align: center;
      margin-left: 15%!important;

    }

    .body-s {
     

      padding-top: 0%;
      font: normal normal normal 18px 'Roboto'!important;
      margin: 30px!important;
      width:90%;
      height: 100%;
      text-align: center;
      margin-left: 5%!important;
    }

    .logo-style-hero {

      display:none;
    }

    .body {
      width:100%!important;
    }

    .grid {
      display:grid;
      grid-template-columns:1fr;
    }

    .form {
      
      padding-left:0px!important;
      margin-left: 0px!important;
      margin-top: 0%!important;
      padding-top: 0%!important;
      display:grid;
      width:auto;
      justify-content: center!important;
      padding-bottom: 30px;
    
      
    }

  
    .bck-from {
      margin-top: 5%!important;
      width: 314px!important;
      height: auto;
    }


    .sendEmail-h1 {

      font: normal normal 600 15px 'Fjalla One';
      padding-left: 9%!important;
    }

    .sendEmail-p {

      font: normal normal 600 14px 'Roboto';
      padding-left: 9%!important;
    }
    
    .sendEmail-p-unidad{

      padding-left: 0%!important;
    }
    

    .col-sm-12{
      padding-left: 0px!important;
      padding-right: 0px!important;
      text-align: center!important;
    }


    .btn-style-asana {

      margin-left: 0px!important;
     
  
    }

    .padding-from{
      padding-right: 25px!important;
      padding-left: 25px!important;
    }


    
  }




  /*view for tablet */   
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {

    .title {
      font-size: 42px!important;
      margin: 0px!important;
      width:70%;
      height:auto;
      padding-top: 15%;
      padding-bottom: 0%;
      text-align: center;
      margin-left: 15%!important;

     
    }

    .body-s {
      padding-top: 0%;
      font-size: 24px!important;
      margin: 30px!important;
      width:70%;
      height: 100%;
      text-align: center;
      margin-left: 15%!important;

    }

    .logo-style-hero {

      display:none;
    }

    .body {
      width:100%!important;
    }

    .grid {
      display:grid;
      grid-template-columns:1fr;
    }

    .form {
      
      padding-left:0px!important;
      margin-left: 0px!important;
      margin-top: 0%!important;
      padding-top: 0%!important;
      display:grid;
      width:auto;
      justify-content: center!important;
      
    
      
    }

  
    .bck-from {
      margin-top: 20%!important;
      width: 400px!important;
      height: auto;
    }


    .sendEmail-h1 {
     
      font: normal normal 600 20px 'Fjalla One';
    }

    .sendEmail-p {

      font: normal normal 600 18px 'Roboto';
    }
    

   
    
    
    
    
  }



 

  @media only screen and (max-width: 320px) {

  
    
    .title {
     

      font: normal normal 600 40px/55px 'Fjalla One'!important;
      margin: 0px!important;
      width:70%;
      height:auto;
      padding-top: 20%;
      padding-bottom: 0%;
      text-align: center;
      margin-left: 15%!important;

    }

    .body-s {
     

      padding-top: 0%;
      font: normal normal normal 18px 'Roboto'!important;
      margin: 30px!important;
      width:90%;
      height: 100%;
      text-align: center;
      margin-left: 5%!important;
    }

    .logo-style-hero {

      display:none;
    }

    .body {
      width:100%!important;
    }

    .grid {
      display:grid;
      grid-template-columns:1fr;
    }

    .form {
      
      padding-left:0px!important;
      margin-left: 0px!important;
      margin-top: 0%!important;
      padding-top: 0%!important;
      display:grid;
      width:auto;
      justify-content: center!important;
      padding-bottom: 0%!important;
    
      
    }

  
    .bck-from {
      margin-top: 5%!important;
      width: 280px!important;
      height: auto;
    }


    .sendEmail-h1 {

      font: normal normal 600 20px 'Fjalla One';
      padding-left: 25px;
      padding-top: 30px;

    }

    .sendEmail-p {

      font: normal normal 600 14px 'Roboto';
      padding-left: 25px;
    }

    .padding-from {

      padding-left: 10px;
      padding-right: 10px;
    
  
      
    }

    .row {

      margin-bottom: 2em!important;
  
  }

    .col-contact {
      padding-bottom: 1em!important;
    }

    .none-col-contact {
      padding-bottom: 0em!important;
      margin-bottom: 0em!important;
    }


    .col-section-style {

      padding-bottom: 0em !important;
      margin-bottom: 0em !important;
  
    }

    
  

    .btn-style {
      font: normal normal normal 18px 'Roboto'!important;
      width: 132px!important;
      height: 50px!important;
    }
    
    .logo-bottom {
      background: url('../img/asanapartners.png') center no-repeat;
      width: 160px!important;
      height:71px!important;
      margin-left: 25%!important;
      background-size: 160px 71px;
      margin-bottom: 100px!important;
      margin-top: 50px!important;

    }
  }
