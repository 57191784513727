

.container_contact {
    background-color: #5B2DED!important;
    height: 515px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    background: no-repeat center/65% url(../img/brackets.png);
    
    
 }
 .textos_section {
    width:70%!important;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 15%;
 }

 

.about-titles {

    padding-left: 200px!important;
    padding-right: 200px!important;
    margin-bottom: 16px!important;
    letter-spacing: var(--unnamed-character-spacing-0)!important;
    color: var(--unnamed-color-ffffff)!important;
    text-align: center!important;
    font: normal normal bold 2em/42px Roboto!important;
    letter-spacing: 0px!important;
    color: #FFFFFF!important;
    opacity: 1!important;
    
 
  }


  .about-body {

    padding-left: 200px!important;
    padding-right: 200px!important;
    letter-spacing: var(--unnamed-character-spacing-0)!important;
    color: var(--unnamed-color-ededed)!important;
    text-align: center!important;
    font: normal normal normal 1.2em/1.875em Roboto!important;
    letter-spacing: 0px!important;
    line-height: 1.5!important;
    color: #EDEDED!important;
    opacity: 1!important;
    margin-bottom: 32px!important;
   
  }


  .btn_contact {

    font: normal normal bold 1em/33px Roboto!important;
    letter-spacing: 0px!important;
    color: #6B4FE1!important;
    opacity: 1!important;
  
    background: #FFFFFF 0% 0% no-repeat padding-box!important;
    border-radius: 10px!important;
    width: 132px!important;
    height: 50px!important;
  
 
  }


  @media only screen and (min-width: 160px) and (max-width: 360px){
   
    .btn_contact {
        
      font: normal normal bold 0.8em Roboto!important;
      color: #6B4FE1!important;
      opacity: 1!important;
      border-radius: 10px!important;
      width: 95px!important;
      height: 45px!important;
      padding-left: 7px!important;
      padding-top: 8px!important;
     
    }
  
  }
  


 @media screen and (max-width: 767px){
    
    .textos_section {
      
        margin-left: 0%;
     }


     .about-titles {

        margin:0px;
        padding:0px;
     }

    .container_contact{

        

        background-color: #5B2DED!important;
        height: 515px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        background: no-repeat center/80% url(../img/brackets_responsive.png);
        padding-left: 25%!important;
    

    }


    div.img_brackets{
   
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div.container_contact{
      padding-left: 0px!important;
    }
    .about-titles {

    
        padding-top: 20px!important;
        text-align: center!important;
        font: normal normal bold 20px/1em Roboto!important;
        letter-spacing: 0px!important;
        color: #FFFFFF!important;
        opacity: 1!important;
        margin:0px!important;
        padding-bottom: 10%!important;
        width: 300px;
        padding-left: 0px!important;
        padding-right: 0px!important;
      }
    
    
      .about-body {
    
        padding-right: 0px!important;
        padding-left: 0px!important;
        text-align: center!important;
        font: normal normal normal 15px/1em Roboto!important;
        letter-spacing: 0px!important;
        line-height: 1.5!important;
        color: #EDEDED!important;
        opacity: 1!important;
       
      }
    
      .btn_contact {
        
        font: normal normal bold 0.8em Roboto!important;
        color: #6B4FE1!important;
        opacity: 1!important;
        border-radius: 10px!important;
        width: 120px!important;
        height: 40px!important;
        padding-left: 5px;
     
      }
   
      
    
 }


 @media screen and (max-width: 320px){ 

    .textos_section {
      
        margin-left: 0%;
     }


     .about-titles {

        margin:0px;
        padding:0px;
     }

    .container_contact{

        

        background-color: #5B2DED!important;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        background: no-repeat center/85% url(../img/brackets_responsive.png);
        padding-left: 25%!important;
    

    }


    .about-titles {

        padding-left: 0px!important;
        padding-right: 0px!important;
        margin-bottom: 0px!important;
        width:100%!important;
        text-align: center!important;
        font: normal normal bold 1em/1.4em Roboto!important;
        letter-spacing: 0px!important;
        color: #FFFFFF!important;
        opacity: 1!important;
        margin:0px!important;
        padding-bottom: 10%!important;
        padding-top:25px!important;
       
      }
    
    
      .about-body {
    
        
        
        text-align: center!important;
        font: normal normal normal 0.7em/0.5em Roboto!important;
        letter-spacing: 0px!important;
        line-height: 1.5!important;
        color: #EDEDED!important;
        opacity: 1!important;
        padding-left: 0px!important;
        padding-right: 0px!important;
       
      }
    
      .btn_contact {
        
        font: normal normal bold 0.8em Roboto!important;
        color: #6B4FE1!important;
        opacity: 1!important;
        border-radius: 10px!important;
        width: 90px!important;
        height: 45px!important;
        padding-left: 10px;
        padding-top: 13px;
       
      }
   

 }


 @media only screen and (min-device-width: 768px) and (max-device-width: 1024px){


  
    .container_contact{

        

        background-color: #5B2DED!important;
        height: 550px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        background: no-repeat center/55% url(../img/brackets_responsive.png);
        padding-left: 0%!important;
    

    }

    .container_contact {
      background-color: #5B2DED!important;
      height: 515px;
      display: flex;
      justify-content: center;
      align-items: center;
      
      background: no-repeat center url(../img/brackets.png);
      background-size: 600px 300px;
      
   }

    .about-titles {

    
      padding-top: 20px!important;
      text-align: center!important;
      font: normal normal bold 25px/1em Roboto!important;
      letter-spacing: 0px!important;
      color: #FFFFFF!important;
      opacity: 1!important;
      margin:0px!important;
      padding-bottom: 10%!important;
      width: 400px;
      padding-left: 0px!important;
      padding-right: 0px!important;
    }
  
  
    .about-body {
  
      padding-right: 0px!important;
      padding-left: 0px!important;
      text-align: center!important;
      font: normal normal normal 18px/1em Roboto!important;
      letter-spacing: 0px!important;
      line-height: 1.5!important;
      color: #EDEDED!important;
      opacity: 1!important;
     
    }

    div.textos_section{

      width:500px!important;
      padding-left: 0px!important;
      margin-left: 0px!important;
    }

 }




 @media screen and(min-device-width: 280px){
  .btn_contact{
    font-size: 10px!important;
    width: 90px!important;
    height: 30px!important;
  }

  h1.about-titles{
    font-size: 15px!important;
  }
  p.about-body{
    font-size: 10px!important;
  }

}






