
#carousel_equipos{
  
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #5b2ded;
  border-radius: 0.25rem;
}


#responsive-carousel_equipos{
  box-shadow: 0px 1px 30px #00000029;
  width: 350px!important;
  height: 350px!important;
  padding-right: 0px;
  padding-left: 0px;
}


#asana_equipos_id{
  padding-top: 100px!important;
  padding-bottom: 100px!important;
}

div.none_color{
  background-color: white!important;
}
div.bar{
  display: none;
}
div.container_slider{
  margin-top:30%;
}

p.texto_carousel{
  color: #ffffff;
  text-align: center;
  margin-left: 80px!important;
  margin-right: 80px!important;

}

h3.h3_titulos{
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px!important;

}

ol.carousel-indicators{
  display: none!important;
}



.carousel-control-prev-icon {
  background-image: url("../../public/img/arrow1.svg")!important;
  background-size: 300px 100px!important;
  
}


.carousel-control-next-icon {
  background-image: url("../../public/img/arrow2.svg")!important;
  background-size: 300px 100px!important;
}

div.containerImg {
  background: url("../../public/img/asana_equipos.png") no-repeat;
  background-size: 850px 500px;
  height: 500px;
  width: 850px;
  margin-top: 80px;

 
  
}



p.sub-asana-equipos{
    text-align: center;
    font: normal normal 600 44px/42px 'Fjalla One';
    letter-spacing: 0px;
    color: #343434;
    opacity: 1;
    padding-bottom: 40px;
}

div.custom-slider{
  display: flex;
}
div.slider_asana {
  height: 475px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px!important;
}

div.container-equipos {

  background: #ffffff!important;
}

div.contacto-asana-img {
  background: url("../img/contacto_izei.jpg") no-repeat;
  width: 30px;
  height: 30px;
}

div.responsive-equipos {
  display: none !important;
}



div.responsive-equipos{
  display: flex;
  justify-content: center;
}


div.container-res{

  width: 210px!important;
 
  text-align: center;
  margin-left: 1px;
  margin-right: 10px;

}


.btn-responsive {
  color: #523BE4!important;
  border: 1px solid #b4b4b4!important;
  border-radius: 31px!important;
  width: 80px;
  height: 30px;
  font-size: 10px!important;
}

td{
  padding-top: 5%;
}

table{
  margin-left: 10px;
}





@media only screen and (min-device-width: 375px) {
  
  table.mobile-asana {
    size: 30%;
  }


  p.sub-asana{
    
    margin-bottom: 25px!important;
  }


  
  p.title-asana {
    padding-top: 0px;
    color: #969696;
    text-align: center;
    font: normal normal 600 50px/42px 'Fjalla One';
    letter-spacing: 0px;
    opacity: 0.5;
    padding-bottom: 0px;
    margin-bottom: 15px!important;
  } 

  
  p.sub-asana-equipos{
    font: normal normal 600 19px/25px 'Fjalla One'!important;
    letter-spacing: 0px;
    color: #343434;
    opacity: 1;
  }

 


}


@media only screen and (min-width: 160px) and (max-width: 360px){
  #responsive-carousel_equipos {
    box-shadow: 0px 1px 30px #00000029;
    width: 250px!important;
    height: 350px!important;
    padding-right: 0px;
    padding-left: 0px;
  }

  p.texto_carousel {
    color: #ffffff;
    text-align: center;
    margin-left: 50px!important;
    margin-right: 50px!important;
  }
  
}

@media only screen and (max-width: 600px) {

  

  div.responsive-equipos {
    display: block !important;
  }

  div.slider_asana {
    display: none !important;
  }
  
  p.title-asana{
    
    font: normal normal 600 40px/42px 'Fjalla One';
  }

  
  
  p.sub-asana{
    font: normal normal 600 19px/18px 'Fjalla One'!important;
    margin-bottom: 25px!important;
  }

  div.container-res{

    width: 180px!important;
    padding-left: 30px;
    padding-right: 30px;    

  }

  .btn-responsive {
    color: #523BE4!important;
    border: 1px solid #b4b4b4!important;
    border-radius: 31px!important;
    width: 70px;
    height: 20px;
    font-size: 10px!important;
  }
  

  
  div.container-equipos {
    background: #fbfbfb;
    padding-bottom:  0px!important;
  }

  
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    div.containerImg {
     
      background-size: 750px 450px!important;
      
  }
}
