div.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  aspect-ratio: auto 220 / 90;
}

div.contacto-asana-header {
  grid-area: 1 / 1 / 2 / 3; 
  background: url('../img/asanaIntro.png')no-repeat;
  background-size: cover;
  background-position-y: -130px;
  height: 300px;
  font: normal normal 600 43px Fjalla One;
  letter-spacing: 0px;
  color: #FFFFFF;

}
img.img-asana-contact{
  padding-top: 18px;
  margin-left: 500px!important;
}
div.contacto-asana-formulario {
  grid-area: 2 / 1 / 3 / 2;
  /*padding-top: 100px;*/
  padding-bottom: 100px;
 
}
div.contacto-asana-porque {
  grid-area: 2 / 2 / 3 / 3;
  padding-top: 100px;
  padding-bottom: 100px;
}


div.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 100px!important;
  
  }
  
  div.div1 { 
    
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-70)/var(--unnamed-line-spacing-84) var(--unnamed-font-family-marr-sans-cond);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    font: normal normal 600 38px 'Roboto';
    letter-spacing: 0px;
    color: #FFFFFF;
    line-height: 47px!important;
    opacity: 1;
    grid-area: 1 / 1 / 2 / 2; 
    padding-top: 0px!important;
    text-align: left!important;
    margin-left: 95px!important;
  
  }



  div.div2 { grid-area: 1 / 2 / 2 / 3; padding-top: 15%; text-align: center;}

.bck-from-asana {
  background-color: rgb(231, 231, 231);
  border-radius: 24px;
  margin-top: 0px;
  width: 410px!important;

}

div.form-asana{
  display: flex;
  justify-content: center;
  
}

div.contacto-asana-porque{
  display: flex;
  justify-content: center;
}

table.asana-table{
  width: 60%;
}

p.asana-tab-contacto{
  color: #000000;
  font-family: 'Fjalla One';
  font-size: 44px;
  padding-top: 25px;
}

.span-table-asana{
  font-size: normal normal normal 16px/20px Roboto;
  color: #848AA2;

  
  
}

td.td-asana{
  padding-left: 30px!important;
}

img.con-asana-{
  margin-left: 0px!important;
}

#logo-con {
  margin-left: 0px!important;
}


@media only screen and (min-width: 320px) and (max-width: 480px){



  div.contacto-asana-header{
    width: 100vw;
    background-size: auto;
    background-position-y: 0px;
  }
  div.parent{
    display: grid;
    grid-template-columns: auto!important;
    margin-top: 50px!important;
   
  }
  
  div.div1{
    grid-area: 1/1!important;
    margin-left: 10%!important;
    margin-right: 10%!important;
    line-height: 30px!important;
  }

  div.div2{
    grid-area: 2/1!important;
   
    
    
  }

  img.img-asana-contact{
    padding-top: 18px;
    
    margin-left: 110px!important;
    
  }
  
  div.contacto-asana-formulario {
    grid-area: 3/1 ;
    padding-top: 0px;
    padding-bottom: 100px;
   
    padding-top: 20px!important;
    width: 100vw;
  }

  div.contacto-asana-porque {
    grid-area: 4 ;
    padding-top: 0px;
    padding-bottom: 100px;
    max-width: 375px!important;
    }

    #asana_contact_id{
      text-align: center!important;
      font-size: 23px!important;
      padding-left: 0px!important;

    }

    .bck-from-asana {
      background-color: rgb(231, 231, 231);
      border-radius: 24px;
      
      margin-top: 5%!important;
      margin-left: 35px!important;
      margin-right: 35px!important;
      height: auto;
  }

  p.asana-tab-contacto {
        color: #535353;
        font-family: 'Fjalla One';
        font-size: 25px;
        text-align: center!important;
    }
  


    .asana-tab-contacto{
      text-align: center!important;
    }

    span.span-table-asana {
      font-size: 13px;
      color: #848AA2;
    }

    .td-asana{
      padding-top: 40px!important;
    }
 

  
}



@media only screen and(min-width: 769px) and (max-width: 1024px){

  
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    img.img-asana-contact {
      padding-top: 18px;
      margin-left: 200px!important; 
    }
    div.parent{
      margin-top:50px!important;
    }

    #asana_contact_id{
      text-align: center!important;
      font-size: 25px!important;
      padding-left: 0px!important;
      text-align:start!important;
    }

    .bck-from-asana {
      background-color: rgb(231, 231, 231);
      border-radius: 24px;
      width: 85%!important;
      
      margin-top: 5%!important;
      margin-left: 35px!important;
      margin-right: 35px!important;
      height: auto;
    }

    table.asana-table {
      width: 80%;
    }


    div.contacto-asana-formulario {
      
      padding-top: 10px;
      padding-bottom: 100px;
    }

    div.contacto-asana-porque {
      padding-top: 20px;
      padding-bottom: 100px;
  }
  

}

