.navbar {
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
    height: 90px;
    display: flex;
    
    align-items: center;
    font-size: 1.2rem;
    top: 0;
    z-index: 999;
    position: -webkit-sticky!important; /* Safari */
	  position: sticky!important;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
    /*padding-left: 7%;*/
    padding-left: 90px!important;
  }

  
  .btn-con {
    background: #523BE4 0% 0% no-repeat padding-box!important;
    border-radius: 5px!important;
    opacity: 1!important;
    border-color: #523BE4!important;
    margin-top: 25px;
    font-size: 14px!important;
  }
  
  

  .navbar-imglogo {
    
    width: 120px;
    height: 62px;
    background: transparent  0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding-bottom: 20px;
    cursor: pointer;

  }

  
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    padding-right: 8%;
   
  }
  
  .nav-item {
    height: 80px;
    
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-size: 14px;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
    color: #fff;
    
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
    align-items: center;
    padding-top: 20px;

  }


  @media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px){ 

    .navbar {
      background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
      height: 90px;
      display: flex;
      
      align-items: center!important;
      justify-content: center!important;
      height: calc(100vh - var ( --navbar-height));
      
    }

    .NavbarItems {
      position: relative;
    }

    .img-fluid {
      width:80%;
      height:80%;
      padding-bottom: 40px!important;
    }
  
    .navbar-container {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
      padding-left: 0px!important;
    }

    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      padding-bottom: 200px!important;
      padding-right: 0px!important;
      padding-top:0px!important;
    }
  
    .nav-menu.active {
      background: rgba(0, 0, 0, 0.9);
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      height: 100vh;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 20%);
      font-size: 1.8rem;
      cursor: pointer;
    
  
      
    }

 
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }

    .btn-con{
      width: 150px!important;
      height: 50px!important;
    }



}



  @media screen and (max-width: 960px) {

    .navbar {
      background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
      height: 90px;
      display: flex;
      
      align-items: center!important;
      justify-content: center!important;
      height: calc(100vh - var ( --navbar-height));
      
    }

    .NavbarItems {
      position: relative;
    }

    .img-fluid {
      width:80%;
      height:80%;
      padding-bottom: 40px!important;
    }
  
    .navbar-container {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
      padding-left: 0px!important;
    }

    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      padding-bottom: 350px!important;
      padding-right: 0px!important;
      padding-top:0px!important;
    }
  
    .nav-menu.active {
      background: rgba(0, 0, 0, 0.9);
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      height: 135vh;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 20%);
      font-size: 1.8rem;
      cursor: pointer;
    
  
      
    }

 
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }

    .btn-con{
      width: 150px!important;
      height: 50px!important;
    }
   
  
    
  }

 


  /*view for tablet */   
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {


    .NavbarItems {
      position: relative;
    }

    .img-fluid {
      width:80%;
      height:80%;
    }
  
    .navbar-container {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
      padding-bottom: 4%;
    }

  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      padding-bottom: 7%;
      padding-right: 0px!important;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      margin-top: 10px;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-110%, 20%);
      font-size: 1.8rem;
      cursor: pointer;
      
  
      
    }

 
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }

   

  }