
.custom-container {
    padding-right: 0px!important;
    padding-left: 0px!important;
    
    height: 788px;
    
}

div.line-about{
    margin-top: 24px;
    width: 800px;
    height: 1px;
    border: 1px solid #070707;
    opacity: 0.16;
    background-color: #070707;
}



.card.card-title-about{
    
    padding-top: 0px!important;
    margin-bottom: 0px!important;
    padding-bottom: 0px!important;
    margin-top: 0px!important;
}


div.grid-asana-cards{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 0px;
    padding-top: 20px;
}

span.text-section{
    color: var(---bcc4cc);
    text-align: left;
    font: normal normal normal 16px/25px Roboto;
    letter-spacing: 0px;
    color: #848AA2;
    opacity: 1;
    display: block;
    width: 200px;
}



span.custom-cards-title-asana {

    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-70)/var(--unnamed-line-spacing-98) var(--unnamed-font-family-marr-sans-cond);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal 600 32px/44px 'Fjalla One';
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 0px!important;
    padding-top: 0px!important;
}

.btn-bottom {
    margin-top:16px!important;
    padding-top: 20px!important;
}




.custom-cards {

    border: none!important;
    width:auto;
    background: rgba(255, 255, 255, 0);
    margin-top: 1em!important;
    padding: none!important;
    
}




.card-text-section {

    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 25px/32px var(--unnamed-font-family-roboto);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-707070);
    text-align: left;
    font: normal normal normal 1.25em Roboto;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    padding-left: 0px!important;
    margin-top: 0.8em;
}


.custom-col {
    background-color: darkgreen;

}

.custom-col-img {
    background-color: rgb(230, 103, 202);
    width: 200px!important;
}

.about-h5 {

    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 30px/25px var(--unnamed-font-family-roboto);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-523be4);
    text-align: left;
    font: normal normal normal 1.875em/25px Roboto;
    letter-spacing: 0px;
    color: #523BE4;
    opacity: 1;
    margin-top: 110px;
    margin-bottom: 20px!important;
}

.about-h1 {

    
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-70)/var(--unnamed-line-spacing-98) var(--unnamed-font-family-marr-sans-cond);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal 600 3em/98px 'Fjalla One';
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 0px!important;
    padding-top: 0px!important;
    
}

.div-about {

    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 17px/25px var(--unnamed-font-family-roboto);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-848AA2);
    font: normal normal normal  16px/25px 'Roboto';
    line-height: 28px!important;
    letter-spacing: 0px;
    color: #848AA2;
    opacity: 1;
    margin-right: 85px;
}




.container {

    
    margin-right: 0;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    width:100%!important;
}

.row {

    margin-right: 0;
    margin-left: 0;
    margin-bottom: 7.5em;

}

.col-about {
  
    text-align:center!important;
    display: flex!important;
    justify-content: center!important;
    
    text-align: justify!important;
    margin-left: 0px!important;
    margin-top:185px;

}


.btn-2em {
    margin-top: 2em;
}

.btn-section-about {
  
    
    font: normal normal bold 1em/33px Roboto!important;
    letter-spacing: 0px!important;
    color: #FFFF!important;
    opacity: 1!important;
    border-color: #523BE4!important;
    background: #523BE4 0% 0% no-repeat padding-box!important;
    border-radius: 10px!important;
    width: 132px!important;
    height: 50px!important;
}



.card-body {

    padding-left: 0px!important;
    padding-bottom: 0px!important;
    margin-bottom: 0px!important;
    padding-right: 0px!important;
}


.logo-style-sc {

    width: 146px!important;
    height: 147px!important;
}


@media screen and (max-width: 820px) {

    

    div.grid-asana-cards{
        display: grid;
        grid-template-columns: auto!important;
    }

    div.mas10{
        grid-area: 1!important;
    }

    div.operacion{
        grid-area: 2!important;
    }

    div.mas15{
        grid-area: 3!important;
    }

    div.experiencia{
        grid-area: 4!important;
    }

    div.mas100{
        grid-area: 5!important;
    }

    div.proyectos{
    
        grid-area: 6!important;
    }
    

    div.line-about{
        display: none;
    }
   .container-custom {
       margin-top: 0!important;
       padding-top: 0!important;
   }

   .logo-style {

        width: 200px!important;
        height: 200px!important;
   }

    
    .about-h5 {

        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 30px/25px var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-523be4);
        text-align: left;
        font: normal normal normal 1.8em 'Roboto';
        letter-spacing: 0px;
        color: #523BE4;
        opacity: 1;
        margin-top: 60px;
        
    }

    .about-h3 {

        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 30px/25px var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-523be4);
        text-align: left;
        font: normal normal normal 1.8em 'Roboto';
        letter-spacing: 0px;
        color: #523BE4;
        opacity: 1;
        margin-top: 60px;
        
    }

    
    
    .about-h1 {
    
        
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-70)/var(--unnamed-line-spacing-98) var(--unnamed-font-family-marr-sans-cond);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal 600 2.375em 'Fjalla One';
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        
    }
    
    .div-about {
    
       
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-707070);
        text-align: left;
        font: normal normal normal  1.2em/25px 'Roboto';
        line-height: 1.5!important;
        letter-spacing: 0px;
        opacity: 1;
        color: #707070;
    }
    
    .custom-cards-title {

        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-70)/var(--unnamed-line-spacing-98) var(--unnamed-font-family-marr-sans-cond);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal 600 2.275em 'Fjalla One';
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;

    }

    .card-text {

        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 25px/32px var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-707070);
        text-align: left;
        font: normal normal normal 15px Roboto;
        letter-spacing: 0px;
        color: #707070;
        opacity: 1;
    }
    

    
  
    
  }




@media screen and (max-width: 600px) {
   
    
    .col-sm-8{
        padding-left: 20px!important;
        padding-right: 20px!important;
        margin-left: 20px!important;
        margin-right: 20px!important;
        
    }

    #section_about_logo_asana{
        padding-left: 20px!important;
       
        margin-left: 20px!important;
    }
    
    .container-custom {
        position:relative!important;
        z-index: 3!important;
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
    .about-h5 {
        padding-top: 0%!important;
        text-align: left;
        margin-top: 15px;
        margin-bottom: 0px;
        font: normal normal normal 18px 'Roboto';
       
        
    }

    .about-h1 {

    
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-70)/var(--unnamed-line-spacing-98) var(--unnamed-font-family-marr-sans-cond);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal 600 28px 'Fjalla One';
        
        
    }
   
    .div-about {
        width:100%!important;
        font: normal normal normal  18px/25px 'Roboto';
        text-align: start!important;
        padding-right: 50px;
        color: #848AA2;
    }

    .btn-2em {
        display:none!important;
    }


   

    .col-about {
  
        text-align:center!important;
        display: flex!important;
        justify-content: left!important;
        
        text-align: justify!important;
        margin-left: 0px!important;
        margin-top:120px;
    
    }

    .logo-style-sc {

        width: 80px !important;
        height: 80px !important;
    }


    .container-custom {
        margin-left: 15px;
        margin-right: 15px;
    }
    

    .card-text-section {

        margin-bottom: 0em!important;
        font: normal normal normal 16px/20px Roboto;
    }

    .card-text-section br {
        display: none!important;
    }

    .btn-bottom {
        padding-top:20px!important;
        margin-bottom: 80px!important;
    }
    
    .btn-section-about {
       
        width: 176px;
        height: 47px;
    }
    div.grid-asana-cards {
      
        grid-gap: 20px!important;
      
    }
    
    
}

@media screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px){


    div.col-sm-8{

        padding-right: 0px!important;
        padding-left: 0px!important;
       
    }

    div.row{
        width: 95%!important;
    }
    div.line-about{
        width: 85%!important;
    }
    
    .container-custom {
        margin-left: 15px;
    }
    .about-h5 {
        padding-top: 0%!important;
        text-align: left;
        margin-top: 15px;
        margin-bottom: 0px;
        
    }

   
    .div-about {
        width:90%!important;
        font: normal normal normal 16px/22px Roboto!important;
    }

    .btn-2em {
        display:none!important;
    }


   

    .col-about {
  
        text-align:center!important;
        display: flex!important;
        justify-content: left!important;
        
        text-align: justify!important;
        margin-left: 0px!important;
        margin-top:50px;
    
    }

    .row {

        margin-bottom: 2em!important;
    
    }

    .custom-cards-title {

        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-70)/var(--unnamed-line-spacing-98) var(--unnamed-font-family-marr-sans-cond);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal 600 1.5em 'Fjalla One';
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        margin : 0 0 0!important;

    }
    
    .card-text-section {

        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 25px/32px var(--unnamed-font-family-roboto);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-707070);
        text-align: left;
        font: normal normal normal 0.9em Roboto;
        letter-spacing: 0px;
        color: #707070;
        opacity: 1;
        
        margin-top: 0.8em;
    }
    
    
}


@media only screen and (max-width: 320px) {

      
    .col-sm-8{
        padding-left: 20px!important;
        padding-right: 20px!important;
        margin-left: 20px!important;
        margin-right: 20px!important;
        
    }
    
    .container-custom {
        position:relative!important;
        z-index: 3!important;
        padding-left: 0px!important;
        padding-right: 0px!important;
    }

    #section_about_logo_asana{
        padding-left: 20px!important;
       
        margin-left: 20px!important;
    }
    
    .div-about {
        width:100%!important;
        font: normal normal normal  18px/25px 'Roboto';
        text-align: start!important;
        padding-right: 50px;
        color: #848AA2;
    }

    div.grid-asana-cards {
      
        grid-gap: 20px!important;
      
    }




}
