div.init-card-asana{
  background: #FBFBFB;
  padding-bottom: 100px!important;

}

.card-deck {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}


.body-card-asana{

  width: 315px!important;
  height: 304px!important;
  padding-top: 45px!important;
}
.title-h1 {
  padding-top: 100px;
  text-align: center;
  font-family: 'Fjalla One';
  font-weight: bold;
}

.bar{
  background-color: #CFCFCF;
  border: 1px solid #CFCFCF;
  border-radius: 4px!important;
  opacity: 1;
  width: 8%;
  margin: auto;
  margin-bottom: 1rem;
}


p.sub-text-card{
  
  font: normal normal normal 20px/29px Roboto;
  letter-spacing: 0px;
  color: #848AA2;
  opacity: 1;
  margin-bottom: 0!important;
  text-align: center;
  margin: 40px 460px 20px 460px!important;
  
}

.card-title {

  color:#848AA2!important;
  text-align:center!important;
  display: flex!important;
  padding-top: 1em!important;
  font-size: 24px!important;
  font-family: 'Roboto';
  margin-bottom: 20px!important;

}

.card-{
  margin-bottom: 40px!important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px!important;
 
}


.card__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top:2em;
  padding-left: 60px;
  padding-right: 60px;
}

.card__wrapper {
  position: relative;
  margin: 40px 0 40px;
  
}

p.card-text {
 
  text-align: left;
  font: normal normal normal 16px/25px Roboto;
  letter-spacing: 0px;
  color: #848AA2;
  opacity: 1;
  padding-left: 25px;
  padding-right: 30px!important;
  margin-top: 0px!important;
  padding-top: 0px!important;
}

.img-card {

  margin-left: 25px!important;
  height: 70px!important;
  
  

}



@media only screen and (min-width: 160px) and (max-width: 360px){
  
  p.sub-text-card {
   
    margin: 0px 0px 0px 0px!important;
  }
}

@media only screen and (min-width: 360px) and (max-width: 768px){
  p.sub-text-card {
   
    margin: 40px 40px 20px 40px!important;
  }

  .card_deck_asana{
    margin-left: -10px!important;
  }
  .card__container {
   
    margin-top: 0px!important;
    padding-right: 60px!important;
    padding-left: 65px!important;

      
  }

  p.card-text {
    
    text-align: left;
    font: normal normal normal 16px/25px Roboto;
    letter-spacing: 0px;
    color: #848AA2;
    opacity: 1;
    margin-right: 30px!important;
    padding-left: 25px;
    padding-right: 30px!important;
    margin-top: 0px!important;
    padding-top: 0px!important;
    margin: 0px 0px 0px 0px!important;
  }

  p.sub-text-card {
    font: normal normal normal 16px/29px Roboto;
    letter-spacing: 0px;
    color: #848AA2;
    opacity: 1;
    margin-bottom: 0!important;
    text-align: center;
    padding-top: 10px;
    line-height: 1.5;
    margin-right: 60px;
    margin-left: 60px;
}
  
  b.modo_responsivo{
    font-size: 18px;
    
  }
 
 

  .card- {
    margin-bottom: 40px!important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px!important;
  }
}





@media screen 
  and (min-device-width: 768px)   
  and (max-device-width: 1024px){

    div.card__container{
      padding-left: 0px;
      padding-right: 0px;
    }

    #titulo_asana{
      margin-left: 100px!important;
      margin-right: 100px!important;
      margin: 0px 100px 0px 100px!important;
    }

    #Id_Cards{
      padding-bottom: 0px!important;
    }

    #texto_card{
      font-size: 14px!important;
      padding-right: 110px!important;
    }

    .body-card-asana{

      width: 100%px!important;
      height: 304px!important;
      padding-top: 45px!important;
    }

    b.modo_responsivo{
      font-size: 15px;
      
    }

    .body-card-asana {
      width: 255px!important;
      height: 304px!important;
      padding-top: 45px!important;
    }

    .card- {
      margin-bottom: 40px!important;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px!important;
      width: 320px!important;
      margin-left: 13px!important;
    }

    .card-title {

      color:#848AA2!important;
      text-align:center!important;
      display: flex!important;
      padding-top: 1em!important;
      font-size: 20px!important;
      font-family: 'Roboto';
      margin-bottom: 20px!important;
    
    }

  
    .card-{
      margin-bottom: 40px!important;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px!important;
      width: 390px!important;
      margin-right: 10px!important;
      margin-left: 10px!important;
    }
    
   

    p.card-text {
    
      text-align: left;
      font: normal normal normal 16px/25px Roboto;
      letter-spacing: 0px;
      color: #848AA2;
      opacity: 1;
      margin-right: 30px!important;
      padding-left: 25px;
      padding-right: 30px!important;
      margin-top: 0px!important;
      padding-top: 0px!important;
    }
      

  }

