
#responsive-carousel{
  box-shadow: 0px 1px 30px #00000029;
  width: 350px!important;
  height: 500px!important;
  border-radius: 0.25rem;
}

div.init_carousel{
  margin-top: 40px;
}

p.card-text-soluciones{
  text-align: center;
  font: normal normal normal 16px/25px Roboto;
  letter-spacing: 0px;
  color: #848AA2;
  opacity: 1;
  padding-left: 25px;
  padding-right: 30px!important;
  margin-top: 0px!important;
  padding-top: 20px!important;
}

.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
  height: 490px!important;
}


div.main-container{
  padding-top: 100px;
  padding-bottom: 100px!important;


}

div.slider_container_asana{
  

  margin-left: 250px;
  margin-right: 250px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 30px #00000029;
  border-radius: 50px;
  
}

div.slide-responsive-asana{
  display: none;
}
/* Controla los cambios en el toggle de Soluciones */
.custom-btn-left{
  color: #523BE4!important;
  border: 2px solid #523BE4!important;
  border-bottom-left-radius: 31px!important;
  border-top-left-radius: 31px!important;
  width: 250px;
}

.custom-btn-center{
  color: #523BE4!important;
  border: 2px solid #523BE4!important;
  width: 250px;
  
}

.custom-btn-right{
  color: #523BE4!important;
  border: 2px solid #523BE4!important;
  border-bottom-right-radius: 31px!important;
  border-top-right-radius: 31px!important;
  width: 250px;
}

/* Cambia el color del btn toggle seleccionado! */ 
.css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected{

  color: #fff!important;
  background-color: #523be4!important;
}

div.container-flex{
  padding-top: 80px;
  justify-content: center;
  display: flex;
  background: #FFFFFF;
  
}

div.custom-slider{
  background: #FFFFFF;
  padding-bottom: 90px;
}

.container-flujo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 80px;
  height: 300px;
  

}

.grid-img{
  
  display: block;
  text-align: end;
  
  

}



.grid-text{
  margin-top: 70px;
  text-align: left;
  font: normal normal normal 18px/27px Roboto;
  letter-spacing: 0px;
  color: #848AA2;
  opacity: 1;
  margin-right: 35%;
}

div.hide-slider-desktop{
  padding-top: 3%;
  display: flex;
  flex-direction: column;
  
}


@media only screen and (min-width: 160px) and (max-width: 360px){
  #responsive-carousel {
    box-shadow: 0px 1px 30px #00000029;
    width: 250px!important;
    height: 500px!important;
  }

}


@media screen 
  and (max-device-width: 768px){
   
    #toggle_btn{
      width: 85%;
      height: 85%;
    }

    #grid_text_id{
      margin-top: 20px!important;
    }
  }

@media screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px){

   
    
    div.slider_container_asana{
  

      margin-left: 50px;
      margin-right: 50px;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 30px #00000029;
      border-radius: 50px;
      
    }

    div.container-flex {
        
        padding-top: 80px;
        justify-content: center;
        display: flex;
        background: #FFFFFF;
    
    }



  }



@media only screen and (max-width: 767px) {
  .carousel.carousel-slider .control-arrow:hover {
    background: rgba(0,0,0,0.2);
    display: none;
  }
  
  div.hide-slider-desktop{
    display: none;
  }

  div.slide-responsive-asana{
    display: flex;
    
  }

  div.main-container{
    padding-top: 50px;
  
  }

  .carousel .thumbs {
    transition: all .15s ease-in;
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap;
    text-align: center;
  }

  .carousel .control-dots .dot {
    transition: opacity .25s ease-in;
    opacity: .3;
    filter: alpha(opacity=30);
    box-shadow: 1px 1px 2px rgb(0 0 0 / 90%);
    background: #a50fe3;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
    background-color: #523BE482!important;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 50px;
  }

  .carousel .control-dots{
    display: block;
    padding-bottom: 10px!important;
    
  }

  

  li.dot.selected{
    background-color: #523BE4!important;

  }
  li.dot{
    
    width: 15px!important;
    height: 15px!important;
  }
  
  .carousel .carousel-status {
    display: none;
  }



  .carousel .thumbs {
      display: none!important;
  }
}



